import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import { locations } from '../../data/locations'

const PageNotFound = () => {
    return (
        <>
        <Header thisLocation={locations[0]} />
        <main id='pageNotFound'>
            <h2>Page Not Found</h2>
            <p>Here are some other Pages:</p>
            <Link to='/' className='link homeLink'>Home Page</Link>
            <section id='linksSection'>
                <div>
                    <h3>Services</h3>
                    <ul>
                        <li><Link to='/?service_type=heating#service_gallery' className='link'>Heating</Link></li>
                        <li><Link to='/?service_type=cooling#service_gallery' className='link'>Cooling</Link></li>
                        <li><Link to='/?service_type=air_quality#service_gallery' className='link'>Air Quality</Link></li>
                        <li><Link to='/?service_type=plumbing#service_gallery' className='link'>Plumbing</Link></li>
                        {/* <li><Link to='/?service_type=refrigeration#service_gallery' className='link'>Refigeration</Link></li> */}
                        {/* <li><Link to='/?service_type=seasonal_start-up_inspections#service_gallery' className='link'>Seasonal Start-up & Inspections</Link></li> */}
                        {/* <li><Link to='/?service_type=attic_fans#service_gallery' className='link'>Attic Fans</Link></li> */}
                        <li><Link to='/?service_type=cleaning#service_gallery' className='link'>Cleaning</Link></li>
                        {/* <li><Link to='/?service_type=home_energy_solutions#service_gallery' className='link'>Home Energy Solutions</Link></li> */}
                    </ul>
                </div>

                <div>
                    <h3>Quick Links</h3>
                    <ul>
                        <li><Link to='/contactus' className='link'>Contact Us</Link></li>
                        <li><a href='https://www.facebook.com/people/AA-Richards-Heating-Cooling-and-Plumbing/100063468763889/'
                            target="_blank" rel="noreferrer" >Facebook</a></li>
                        <li><Link to="/faq" className='link'>FAQ</Link></li>
                    </ul>
                </div>
            </section>
        </main>
        </>
    )
}

export default PageNotFound