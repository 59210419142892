import threeVans from '../media/images/Summer/three-vans.webp'
import twoVansFall from '../media/images/Fall/twoVansFall.webp'
import twoVansSpring1920 from '../media/images/Spring/twoVansSpring-1920.webp'
import twoVansSpring960 from '../media/images/Spring/twoVansSpring-960.webp'
import twoVansSpring720 from '../media/images/Spring/twoVansSpring-720.webp'

export const getThisSeason = () => {
    // '1995-12-17'
    const toDay = new Date()

// toDay.getMonth()+1
    switch(toDay.getMonth()+1) {
        case 12:
        case 1:
        case 2:
            return {season: "Winter", seasonID: 1, showcase: twoVansSpring960, 
            srcSet: `${twoVansSpring960} 960w, ${twoVansSpring720} 720w, ${twoVansSpring1920} 1920w`}
        case 3:
        case 4:
        case 5:
            return {season: "Spring", seasonID: 2, showcase: threeVans}
        case 6:
        case 7:
        case 8:
            return {season: "Summer", seasonID: 3, showcase: twoVansSpring960,
            srcSet: `${twoVansSpring960} 960w, ${twoVansSpring720} 720w, ${twoVansSpring1920} 1920w`}
        case 9:
        case 10:
        case 11:
            return {season: "Fall", seasonID: 4, showcase: twoVansFall}
        default:
            return {season: "The season", seasonID: 0, showcase: twoVansSpring960,
            srcSet: `${twoVansSpring960} 960w, ${twoVansSpring720} 720w, ${twoVansSpring1920} 1920w`}
      }
}

export const getThisLocation = (location: string) => {

    switch(location.toLocaleLowerCase()) {
        case "matawan":
            return {
                name: 'Matawan',
                phone: {
                  display: '(732) 290 8466',
                  href: 'tel:+17322908466',
                },
                address: '84 Main Street Matawan, NJ 07747',
              }

        case "keyport":
            return {
                name: "Keyport",
                phone: {
                    href: 'tel:+17323355859',
                    display: '(732) 335 5859'
                },
                address: ''
              }

        case "toms-river":
            return {
                name: "Tom's River",
                phone: {
                    href: 'tel:+17322810003',
                    display: '(732) 281 0003'
                },
                address: '835 Mantoloking Rd Brick Township, NJ 08723'
              }

        case "brick":
            return {
                name: 'Brick',
                phone: {
                    href: 'tel:+17322810003',
                    display: '(732) 281 0003'
                },
                address: '835 Mantoloking Rd Brick Township, NJ 08723'
              }

        case "belmar":
            return {
                name: "Belmar",
                phone: {
                    href: 'tel:+17326818282',
                    display: '(732) 681 8282'
                },
                address: ''
              }

        case "holmdel-nj":
            return {
                name: 'Holmdel',
                phone: {
                  href: 'tel:+17322810003',
                  display: '(732) 281 0003'
                },
                address: ''
              }

        case "colts-neck-nj":
            return {
                name: 'Colts Neck',
                phone: {
                  href: 'tel:+17322810003',
                  display: '(732) 281 0003'
                },
                address: ''
              }

        case "long-branch-nj":
            return {
                name: 'Long Branch',
                phone: {
                  href: 'tel:+17322810003',
                  display: '(732) 281 0003'
                },
                address: ''
              }

        case "north-brunswick-nj":
            return {
                name: 'North Brunswick',
                phone: {
                  href: 'tel:+17322810003',
                  display: '(732) 281 0003'
                },
                address: ''
              }

        case "howell":
            return {
                name: "Howell",
                phone: {
                    href: 'tel:+17323644343',
                    display: '(732) 364 4343'
                },
                address: ''
              }

        case "red-bank":
            return {
                name: 'Red Bank',
                phone: {
                  href: 'tel:+17326715115',
                  display: '(732) 671 5115'
                },
                address: ''
              }

        case "red-bank-nj":
            return {
                name: 'Red Bank',
                phone: {
                  href: 'tel:+17326715115',
                  display: '(732) 671 5115'
                },
                address: ''
              }

        case "forked-river-nj":
            return {
                name: 'Forked River',
                phone: {
                  href: 'tel:+16096939777',
                  display: '(609) 693 9777'
                },
                address: ''
              }

        default:
            return {
                name: "Tom's River",
                phone: {
                    href: 'tel:+17322810003',
                    display: '(732) 281 0003'
                },
                address: '835 Mantoloking Rd Brick Township, NJ 08723',
                default: true
              }
      }
}