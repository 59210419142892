export const locations = [
    {
        name: "Tom's River",
        slug: "",
        phone: {
            href: 'tel:+17322810003',
            display: '(732) 281 0003'
        },
        address: '835 Mantoloking Rd Brick Township, NJ 08723'
    },
    {
        name: "Tom's River",
        slug: "/toms-river",
        phone: {
            href: 'tel:+17322810003',
            display: '(732) 281 0003'
        },
        address: '835 Mantoloking Rd Brick Township, NJ 08723'
    },
    {
        name: 'Matawan',
        slug: '/matawan',
        phone: {
            display: '(732) 290 8466',
            href: 'tel:+17322908466',
        },
        address: '84 Main Street Matawan, NJ 07747',
    },
    {
        name: "Keyport",
        slug: "/keyport",
        phone: {
            href: 'tel:+17323355859',
            display: '(732) 335 5859'
        },
        address: ''
      },
      {
        name: 'Brick',
        slug: "/brick",
        phone: {
            href: 'tel:+17322810003',
            display: '(732) 281 0003'
        },
        address: '835 Mantoloking Rd Brick Township, NJ 08723'
      },
      {
        name: "Belmar",
        slug: "/belmar",
        phone: {
            href: 'tel:+17326818282',
            display: '(732) 681 8282'
        },
        address: ''
      },
      {
        name: 'Holmdel',
        slug: "/holmdel-nj",
        phone: {
          href: 'tel:+17322810003',
          display: '(732) 281 0003'
        },
        address: ''
      },
      {
        name: 'Holmdel',
        slug: "/holmdel",
        phone: {
          href: 'tel:+17322810003',
          display: '(732) 281 0003'
        },
        address: ''
      },
      {
        name: 'Colts Neck',
        slug: "/colts-neck-nj",
        phone: {
          href: 'tel:+17322810003',
          display: '(732) 281 0003'
        },
        address: ''
      },
      {
        name: 'Long Branch',
        slug: "/long-branch-nj",
        phone: {
          href: 'tel:+17322810003',
          display: '(732) 281 0003'
        },
        address: ''
      },
      {
        name: 'North Brunswick',
        slug: "/north-brunswick-nj",
        phone: {
          href: 'tel:+17322810003',
          display: '(732) 281 0003'
        },
        address: ''
      },
      {
        name: "Howell",
        slug: "/howell",
        phone: {
            href: 'tel:+17323644343',
            display: '(732) 364 4343'
        },
        address: ''
      },
      {
        name: 'Red Bank',
        slug: "/red-bank",
        phone: {
          href: 'tel:+17326715115',
          display: '(732) 671 5115'
        },
        address: ''
      },
      {
        name: 'Red Bank',
        slug: "/red-bank-nj",
        phone: {
          href: 'tel:+17326715115',
          display: '(732) 671 5115'
        },
        address: ''
      },
      {
        name: 'Forked River',
        slug: "/forked-river-nj",
        phone: {
          href: 'tel:+16096939777',
          display: '(609) 693 9777'
        },
        address: ''
      }
]