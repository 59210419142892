export const reviews = [
    {
        id: 4,
        name: "Jim M",
        stars: 5,
        text: "I had them install a navien tankless water heater at my Seaside home. they ripped out the old one and installed the new one all in one day. They work fast and the price wasn't bad. I'll definitely use them again.",
        date: "July 2024"
    },
    {
        id: 3,
        name: "Brian",
        stars: 5,
        text: "Needed hot water heater replacement. Installer worked to ensure the install was done correctly and safely.",
        date: "May 2023"
    },
    {
        id: 2,
        name: "Dorothy Chulsky",
        stars: 5,
        text: "All I want to say is Chris and  his partner worked very hard to get the job done, and Karen in the office 🏬 was a sweetheart. Very honest people. I wish all the staff Happy Holidays 😊 Dorothy Chulsky and Chris Sodano, Whiting NJ.",
        date: "July 2022"
    },
    {
        id: 1,
        name: "Marilyn Durden",
        stars: 5,
        text: "When I turned my heater on for the first time, it did not go on. Being new in the area, I screened some companies from friends, Google and Angi. Chris of A.A. Richards Heating, Cooling, & Plumbing was selected due to his experience and positive reviews. When Chris came to evaluate the boiler, his initial assessment was grim due to the age and condition of the system. He did some research and was able to make the necessary repairs!  In addition, the service were affordable. I was comfortable in my home as the temperature dropped below 32 degrees that night!! When this system has to be replaced, he is the person I will call.",
        date: "November 2021"
    }
]