import React from 'react'

// Animacion
// import { motion } from "framer-motion"

const Showcase = ({image, textBox}:any) => {
    return (
        <section className='showcase'>
            <img id='showcaesImg' src={image.url} srcSet={image.srcSet}
             alt={image.alt} width={image.width} height={image.height} />
            <div className='showBox'>
                <div className='textBox'>
                    {textBox}
                </div>
            </div>
        </section>
    )
}

export default Showcase