import { reviews } from '../../data/reviews';
import './reviewStyle.css';

// Icons
import { MdStar } from 'react-icons/md'

const Reviews = () => {
    return (
        <div className='reviewsBox'>
            <div className='reviewsInnerBox'>
            {reviews.map((review) => (
                <div key={review.id} className='review'>
                    <div className='reviewStars'>
                        <MdStar className='star' size={30} />
                        <MdStar className='star' size={30} />
                        <MdStar className='star' size={30} />
                        <MdStar className='star' size={30} />
                        <MdStar className='star' size={30} />
                    </div>
                    <div className='reviewText'>
                        <p>{review.text}</p>
                    </div>
                    <p className='reviewDate'>{`${review.name}. ${review.date}`}</p>
                </div>
                ))}
            </div>
        </div>
    )
}

export default Reviews;