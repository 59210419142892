import { useEffect } from 'react'
import { NavLink, Link, useLocation } from "react-router-dom"

// CSS
import '../../CSS/navDropdown.css'

import logo from '../../media/images/logo75.webp'
import { FaFacebook, FaYelp } from 'react-icons/fa'

const Header = ({ thisLocation }:any) => {
    const location = useLocation()

    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) elem.scrollIntoView({behavior: "smooth"})
        } else window.scrollTo({top:0, left:0, behavior: "smooth"})
}, [location])

    return (
        <header>
            <Link title='Home Page' to={thisLocation.slug + "/#home"}>
                <img src={logo} alt="" width={75} height={75} /></Link>
            
            <Link title='Home Page' to={thisLocation.slug + "/#home"}>
                <div className='companyNameBox'>
                <h1>A.A. Richards HVAC</h1>
                <p><span className='textRed'>Heating</span>, <span 
                    className='textBlue'>Cooling</span>, &amp; Plumbing LLC</p>
                </div>
            </Link>
            <nav>
                <ul>
                    <li className='menu-item'>
                        <NavLink to={thisLocation.slug +  "/#services"} className='navLink'>Services</NavLink>
                        <ol className="sub-menu">
                            <li className="menu-item">
                                <NavLink to={thisLocation.slug + "/heating"} 
                                    className='navLink'>Heating</NavLink></li>

                            <li className="menu-item">
                                <NavLink to={thisLocation.slug + "/?service_type=cooling#service_gallery"}
                                    className='navLink'>Cooling</NavLink></li>

                            <li className="menu-item">
                                <NavLink to={thisLocation.slug + "/?service_type=plumbing#service_gallery"}
                                    className='navLink'>Plumbing</NavLink></li>

                            <li  className="menu-item">
                                <Link to={thisLocation.slug + "/?service_type=air_quality#service_gallery"}
                                className='navLink'>Air Quality</Link></li>
                                
                            <li  className="menu-item">
                                <Link to={thisLocation.slug + "/?service_type=cleaning#service_gallery"}
                                className='navLink'>Cleaning</Link></li>

                            <li className="menu-item">
                                <NavLink to={thisLocation.slug + "/#service_gallery"}
                                    className='navLink'>All Services</NavLink></li>
                        </ol>
                    </li>
                    <li>
                        <NavLink to={thisLocation.slug + "/faq"}
                            className='navLink'>FAQ</NavLink>
                    </li>
                    <li>
                        <NavLink to={thisLocation.slug + "/contactus"}
                            className='navLink'>Contact Us</NavLink>
                    </li>
                    <li>
                        <a target='_black' href="https://www.facebook.com/p/AA-Richards-Heating-Cooling-and-Plumbing-100063468763889/?locale=website"
                            className='navLink'><FaFacebook size={30} /></a>
                    </li>
                    {/* <li>
                        <a target='_black' href="https://www.yelp.com/biz/a-a-richards-heating-cooling-and-plumbing-keyport?locale=website"
                            className='navLink'><FaYelp size={30}/></a>
                    </li> */}
                </ul>
            </nav>
        </header>
    )
}

export default Header