import React from 'react'
import { Link } from 'react-router-dom'

// Icons
import { FaCcVisa, FaCcDiscover, FaCcMastercard } from 'react-icons/fa'
import { SiAmericanexpress } from 'react-icons/si'

const Footer = () => {
    return (
        <footer>
            <div id='footerBox'>
            <div className='footerBlock'>
                <h3>About This Site & The Company</h3>
                <ul>
                    <li><Link to='/doc/privacy_policy' className='link'>privacy-policy</Link></li>
                    <li><Link to='/doc/cookies_policy' className='link'>Cookies Policy</Link></li>
                    <li>Owner: Christopher K. Madden</li>
                    <li>NJ Master HVACR Contractor License #19HC00030500.</li>
                    <li>&copy; {new Date().getFullYear()} AA Richards HVAC Heating, Cooling, &amp; PLumbing LLC, All rights reserved.</li>
                </ul>
            </div>
            <div className='footerBlock'>
                <h3>Services</h3>
                <ul>
                    <li><Link to='/?service_type=heating#service_gallery' className='link'>Heating</Link></li>
                    <li><Link to='/?service_type=cooling#service_gallery' className='link'>Cooling</Link></li>
                    <li><Link to='/?service_type=air_quality#service_gallery' className='link'>Air Quality</Link></li>
                    <li><Link to='/?service_type=plumbing#service_gallery' className='link'>Plumbing</Link></li>
                    {/* <li><Link to='/?service_type=refrigeration#service_gallery' className='link'>Refigeration</Link></li> */}
                    {/* <li><Link to='/?service_type=seasonal_start-up_inspections#service_gallery' className='link'>Seasonal Start-up & Inspections</Link></li> */}
                    {/* <li><Link to='/?service_type=attic_fans#service_gallery' className='link'>Attic Fans</Link></li> */}
                    <li><Link to='/?service_type=cleaning#service_gallery' className='link'>Cleaning</Link></li>
                    {/* <li><Link to='/?service_type=home_energy_solutions#service_gallery' className='link'>Home Energy Solutions</Link></li> */}
                </ul>
            </div>
            <div className='footerBlock'>
                <h3>Quick Links</h3>
                <ul>
                    <li><Link to='contactus' className='link'>Contact Us</Link></li>
                    <li><a href='https://www.facebook.com/people/AA-Richards-Heating-Cooling-and-Plumbing/100063468763889/'
                        target="_blank" rel="noreferrer" >Facebook</a></li>
                        {/* <li><a href='https://www.yelp.com/biz/a-a-richards-heating-cooling-and-plumbing-keyport?locale=website'
                        target="_blank" rel="noreferrer" >Yelp</a></li> */}
                    <li><Link to="/faq" className='link'>FAQ</Link></li>
                    {/* <li><a href='https://aarichardshvac.business.site/'>A.A. Richards Heating, Cooling, & Plumbing</a></li> */}
                </ul>
            </div>

            <div className='footerBlock'>
                <h3>Payment Methods</h3>
                <ul>
                    <li>
                        <FaCcVisa size={20} title="Visa" className="creditCard" /> 
                        <FaCcDiscover size={20} title="Discover" className="creditCard" /> 
                        <FaCcMastercard size={20} title="Mastercard" className="creditCard" /> 
                        <SiAmericanexpress size={20} title="American Express" className="creditCard" />
                    </li>
                    <li>Personal Check</li>
                    <li>Cash</li>
                    <li>Financing</li>
                </ul>
            </div>
            </div>
            <a id='madeBy' href='https://www.thebosapps.com/?utm_source=aarichardshvac'>Powered by BOSApps</a>
        </footer>
    )
}

export default Footer