import { Suspense, lazy } from 'react';
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";

// CSS
import './App.css';
import './loader.css'
import './CSS/article.css'

// components
// import Header from './components/Header';
import Footer from './components/Footer';

import { locations } from './data/locations'

// Pages
import Home from './pages/Home'
import PageNotFound from './pages/PageNotFound'
// import OFS from './pages/OFS';

// Heating
const Heating = lazy(() => import('./pages/Heating'))
const Furnaces = lazy(() => import('./pages/Services/heating/Furnaces'))
const Boilers = lazy(() => import('./pages/Services/heating/Boilers'))
const HeatPumps = lazy(() => import('./pages/Services/heating/HeatPumps'))
const HeatingRepair = lazy(() => import('./pages/HeatingRepair'))
// const OilToGasConversions = lazy(() => import('./pages/Services/heating/OilToGasConversions'))

// Cooling
const AirConditioning = lazy(() => import('./pages/Services/cooling/ACInstalls'))
const ACCleaning = lazy(() => import('./pages/Services/cooling/ACCleaning'))

// Air Quality
const Humidifiers = lazy(() => import('./pages/Services/airQuality/Humidifiers'))
const Filters = lazy(() => import('./pages/Services/airQuality/Filters'))
const UVFilters = lazy(() => import('./pages/Services/airQuality/UVFilters'))

// Plumbing
const WaterHeaters = lazy(() => import('./pages/Services/plumbing/WaterHeaters'))
const TanklessWaterHeaters = lazy(() => import('./pages/Services/plumbing/TanklessWaterHeaters'))
const DrainClearing = lazy(() => import('./pages/Services/plumbing/DrainClearing'))
const SewerLineReplacement = lazy(() => import('./pages/Services/plumbing/SewerLineReplacement'))
const WaterHeaterInstallation = lazy(() => import('./pages/WaterHeaterInstallation'))
const GasLineInstallation = lazy(() => import('./pages/GasLineInstallation'))

// Cleaning
const ChimneyCleaning = lazy(() => import('./pages/Services/cleaning/ChimneyCleaning'))
const DryerVentCleaning = lazy(() => import('./pages/Services/cleaning/DryerVentCleaning'))


const Contact = lazy(() => import('./pages/Contact'))
const FAQ = lazy(() => import('./pages/FAQ'))
// const Services = lazy(() => import('./pages/Services'))
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const MoreInfo = lazy(() => import('./pages/MoreInfo'))

function App() {
  const location = useLocation();
    // return (
    //   <OFS />
    // )
  return (
    <Suspense fallback={(<div id='loader'><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>Loading</div>)}>
      <Routes>
        {/* Default Routes */}
        {/* <LocationRoutes locations={locations}/> */}

        { locations.map((location:any) => (
            <>
              <Route key={location.slug + "-home"} path={location.slug} element={<Home thisLocation={location} />} />

              <Route key={location.slug + "-contactus"} path={location.slug + '/contactus'} 
                element={<Contact thisLocation={location} />} />

              <Route key={location.slug + "-faq"} path={location.slug + '/faq'}
                element={<FAQ thisLocation={location} />} />

              <Route key={location.slug + "-services"} path={location.slug + '/services'}
                element={<Home thisLocation={location} />} />

              {/* Heating */}
              <Route key={location.slug + "-heating"} path={location.slug + '/heating'}
                element={<Heating thisLocation={location} />} />

              <Route key={location.slug + "-heating-repair"} path={location.slug + '/heating-repair'}
                element={<Home thisLocation={location} />} />

              <Route key={location.slug + "-heating-tune-up"} path={location.slug + '/heating-tune-up'}
                element={<Home thisLocation={location} />} />

              <Route key={location.slug + "-home-heat-pumps"} path={location.slug + '/home-heat-pumps'}
                element={<Home thisLocation={location} />} />

              <Route key={location.slug + "-furnaces"} path={location.slug + '/furnaces'}
                element={<Furnaces thisLocation={location} />} />

              <Route key={location.slug + "-boilers"} path={location.slug + '/boilers'}
                element={<Boilers thisLocation={location} />} />

              <Route key={location.slug + "-heat-pumps"} path={location.slug + '/heat-pumps'}
                element={<HeatPumps thisLocation={location} />} />

              <Route key={location.slug + "-heating-repair"} path={location.slug + '/heating-repair'}
                element={<HeatingRepair thisLocation={location} />} />

              {/* <Route key={location.slug + "-oil-to-gas-conversions"} path={location.slug + '/oil-to-gas-conversions'}
                element={<OilToGasConversions thisLocation={location} />} /> */}

              {/* Cooling */}
              <Route key={location.slug + "-cooling"} path={location.slug + '/cooling'}
                element={<AirConditioning thisLocation={location} />} />

              <Route key={location.slug + "-air-conditioning"} path={location.slug + '/air-conditioning'}
                element={<AirConditioning thisLocation={location} />} />

              <Route key={location.slug + "-ac-repair"} path={location.slug + '/ac-repair'}
                element={<AirConditioning thisLocation={location} />} />

              <Route key={location.slug + "-refrigeration-company"} path={location.slug + '/refrigeration-company'}
                element={<AirConditioning thisLocation={location} />} />

              {/* Air Quality */}
              <Route key={location.slug + "-humidifiers"} path={location.slug + '/humidifiers'}
                element={<Humidifiers thisLocation={location} />} />

              <Route key={location.slug + "-filters"} path={location.slug + '/filters'}
                element={<Filters thisLocation={location} />} />

              <Route key={location.slug + "-uv-filters"} path={location.slug + '/uv-filters'}
                element={<UVFilters thisLocation={location} />} />

              {/* Plumbing */}
              <Route key={location.slug + "-water-heaters"} path={location.slug + '/water-heaters'}
                element={<WaterHeaters thisLocation={location} />} />

              <Route key={location.slug + "-tankless-water-heaters"} path={location.slug + '/tankless-water-heaters'}
                element={<TanklessWaterHeaters thisLocation={location} />} />

              <Route key={location.slug + "-sewer-line-replacement"} path={location.slug + '/sewer-line-replacement'}
                element={<SewerLineReplacement thisLocation={location} />} />

              <Route key={location.slug + "-drain-clearing"} path={location.slug + '/drain-clearing'}
                element={<DrainClearing thisLocation={location} />} />

              <Route key={location.slug + "-water-heater-installation"} path={location.slug + '/water-heater-installation'}
                element={<WaterHeaterInstallation thisLocation={location} />} />

              <Route key={location.slug + "-gas-line-installation"} path={location.slug + '/gas-line-installation'}
                element={<GasLineInstallation thisLocation={location} />} />

              {/* Cleaning */}
              <Route key={location.slug + "-chimney-cleaning"} path={location.slug + '/chimney-cleaning'}
                element={<ChimneyCleaning thisLocation={location} />} />

              <Route key={location.slug + "-dryer-vent-cleaning"} path={location.slug + '/dryer-vent-cleaning'}
                element={<DryerVentCleaning thisLocation={location} />} />

              <Route key={location.slug + "-duct-cleaning"} path={location.slug + '/duct-cleaning'}
                element={<ACCleaning thisLocation={location} />} />

              <Route key={location.slug + "-drain-cleaning"} path={location.slug + '/drain-cleaning'}
                element={<DrainClearing thisLocation={location} />} />

              <Route key={location.slug + "-air-duct-cleaning"} path={location.slug + '/air-duct-cleaning'}
                element={<ACCleaning thisLocation={location} />} />

              {/* Other */}
              <Route key={location.slug + "-doc/cookies-policy"} path={location.slug + '/doc/cookies-policy'}
                element={<CookiesPolicy />} />

              <Route key={location.slug + "-doc/privacy-policy"} path={location.slug + '/doc/privacy-policy'}
                element={<PrivacyPolicy />} />

              <Route key={location.slug + "-allabouthvac"} path={location.slug + '/allabouthvac'}
                element={<MoreInfo thisLocation={location} />} />

              <Route key={location.slug + "-gallery"} path={location.slug + '/gallery'}
                element={<MoreInfo thisLocation={location} />} />

              <Route key={location.slug + "-about"} path={location.slug + '/about'}
                element={<MoreInfo thisLocation={location} />} />

              <Route key={location.slug + "-areas-we-serve"} path={location.slug + '/areas-we-serve'}
                element={<Contact thisLocation={location} />} />

              <Route key={location.slug + "-PageNotFound"} path={location.slug + '/*'} element={<PageNotFound />} />

            </>
        ))}

        {/* Page Not Found */}
        <Route path="/*" element={<PageNotFound />} />

        </Routes>
      {!location.pathname.includes('doc') ? <Footer />: ''}
    </Suspense>
  );
}

export default App;
