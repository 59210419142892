import { useState  } from 'react'
import { Link } from "react-router-dom";

// Helmet
import { Helmet } from 'react-helmet'

// Animacion
// import { motion } from 'framer-motion'
// import { goldSealInitial, goldSealAnimate, goldSealTransition } from '../../animacions'

// Components
import Showcase from '../../components/Showcase'

// import { GiAutoRepair } from 'react-icons/gi'

// Images
import { LazyLoadImage } from 'react-lazy-load-image-component'

// Guarantee Service
// import guarantee_service_Financing from '../../media/images/ServiceGuarantees/guarantee-service-Financing.webp'
import guarantee_service_Free_Estimates from '../../media/images/ServiceGuarantees/guarantee-service-Free-Estimates.webp'
import guarantee_service_Response_Time from '../../media/images/ServiceGuarantees/guarantee-service-Response-Time.webp'
import guarantee_service_Warranty from '../../media/images/ServiceGuarantees/guarantee-service-Warranty.webp'
import coloredEmergencyService from '../../media/images/247-colored-150x110.webp'

// import theTeam from '../../media/images/the-AA-team.webp'
// import todaysSpecial from '../../media/images/2022-AAR-summer-offer.webp'

// services
import heating from '../../media/images/services/Heating101.webp'
import cooling from '../../media/images/services/cooling.webp'
import airquality from '../../media/images/services/removing-air-filter.webp'
import plumbing from '../../media/images/services/Plumbing.webp'
// import refrigeration from '../../media/images/services/Interior-of-Industrial-Freezer-300.webp'
// import thermostat from '../../media/images/services/thermostat.webp'
// import atticFan from '../../media/images/services/attic-fan.webp'
// import hvacInspection from '../../media/images/services/hvac-inspection-300x200.webp'
import ductCleaning from '../../media/images/services/duct-cleaning-300.webp'
// import electrical from '../../media/images/services/electrical.webp'
// import homeEnergySolutions from '../../media/images/services/home-energy-solutions.webp'

// heating
import furnace from '../../media/images/services/furnace.webp'
import boiler from '../../media/images/services/boiler.webp'
import heatPump from '../../media/images/services/goodman_heat_pump_condenser.webp'
// cooling
import condenserAndCoilReplacement from '../../media/images/services/condenserAndCoilReplacement.webp'

// Air Quality
import humidifier from '../../media/images/services/humidifier.webp'
import uvFilter from '../../media/images/services/uv-filter.webp'
import filters from '../../media/images/services/air_filters-300.webp'

// Plumbing
import waterHeaters from '../../media/images/services/Water-Heaters.webp'
import tanklessWaterHeater from '../../media/images/services/tankless-water-heaters-300.webp'
import sewerLineReplacement from '../../media/images/services/Sewer-Line-Replacement.webp'
import drainClearing from '../../media/images/services/drainClearing.webp'

// Cleaning
import chimneyCleaning from '../../media/images/services/chimneyCleaning.webp'
import dryerVentCleaning from '../../media/images/services/dryerVentCleaning.webp'
import { getThisSeason } from '../../businessLogic'

// brands
import goodman from '../../media/images/brands/ac-furnace-system-500.webp'
import navien from '../../media/images/brands/NFB-H-boilers-units-Navien-500.webp'
import trane from '../../media/images/brands/trane-hvac-500.webp'
import carrier from '../../media/images/brands/carrier-500.webp'
import Header from '../../components/Header';
import Reviews from '../../components/Reviews';

// import seniors from '../../media/images/Seniors.webp'
// import firefighters from '../../media/images/Firefighters.webp'
// import eMTMembers from '../../media/images/EMT-Members.webp'
// import veterans from '../../media/images/Veterans.webp'
// import lawEnforcementPersonnel from '../../media/images/law-Enforcement-Personnel.webp'

const services = [
    // Top Level
    {level: 'top', url: '/?service_type=heating#service_gallery', title: 'Heating', id: 'heating', img: heating},
    {level: 'top', url: '/?service_type=cooling#service_gallery', title: 'Cooling', id: 'cooling', img: cooling},
    {level: 'top', url: '/?service_type=air_quality#service_gallery', title: 'Air Quality', id: 'airquality', img: airquality},
    {level: 'top', url: '/?service_type=plumbing#service_gallery', title: 'Plumbing', id: 'plumbing', img: plumbing},
    // {level: 'top', url: '/?service_type=refrigeration#service_gallery', title: 'Refrigeration', id: 'refrigeration', img: refrigeration},
    // {level: 'top', url: '/?service_type=seasonal_start-up_inspections#service_gallery', title: 'Seasonal Start-up & Inspections', 
    // id: 'seasonalStartUpInspections', img: hvacInspection},
    // {level: 'top', url: '/?service_type=thermostats#service_gallery', title: 'Thermostats', id: 'thermostats', img: thermostat},
    // {level: 'top', url: '/?service_type=attic_fans#service_gallery', title: 'Attic Fans', id: 'atticFans', img: atticFan},
    {level: 'top', url: '/?service_type=cleaning#service_gallery', title: 'Cleaning', id: 'cleaning', img: ductCleaning},
    // {level: 'top', url: '/?service_type=electrical#service_gallery', title: 'Electrical', id: 'electrical', img: electrical},
    // {level: 'top', url: '/?service_type=home_energy_solutions#service_gallery', title: 'Home Energy Solutions', 
    // id: 'homeEnergySolutions', img: homeEnergySolutions},

    // Heating
    {level: 'heating', url: '/furnaces', title: 'Furnaces', 
    id: 'furnaces', img: furnace},
    {level: 'heating', url: '/boilers', title: 'Boilers', 
    id: 'boilers', img: boiler},
    {level: 'heating', url: '/heat-pumps', title: 'Heat Pump', 
    id: 'heatPump', img: heatPump},

    // Cooling
    {level: 'cooling', url: '/air-conditioning', 
    title: 'AC Installation & Replacement', 
    id: 'condenserAndCoilReplacement', img: condenserAndCoilReplacement},
    {level: 'cooling', url: '/duct-cleaning', title: 'AC Cleaning',
    id: 'aCCleaning', img: ductCleaning},

    // Air Quality
    {level: 'air_quality', url: '/humidifiers', title: 'Humidifiers', 
    id: 'humidifiers', img: humidifier},
    {level: 'air_quality', url: '/filters', title: 'Filters', 
    id: 'filters', img: filters},
    {level: 'air_quality', url: '/uv-filters', title: 'UV Filters', 
    id: 'uvFilters', img: uvFilter},

    // Cleaning
    {level: 'cleaning', url: '/chimney-cleaning', title: 'Chimney Cleaning', 
    id: 'chimneyCleaning', img: chimneyCleaning},
    {level: 'cleaning', url: '/dryer-vent-cleaning', title: 'Dryer Vent Cleaning', 
    id: 'dryerVentCleaning', img: dryerVentCleaning},
    {level: 'cleaning', url: '/duct-cleaning', title: 'Duct Cleaning', 
    id: 'ductCleaning', img: ductCleaning},

    // Plumbing
    {level: 'plumbing', url: '/water-heaters', title: 'Water Heaters', 
    id: 'waterHeaters', img: waterHeaters},
    {level: 'plumbing', url: '/tankless-water-heaters', title: 'Tankless Water Heaters', 
    id: 'tanklessWaterHeater', img: tanklessWaterHeater},
    {level: 'plumbing', url: '/sewer-line-replacement', title: 'Sewer & Water Line Replacement', 
    id: 'sewerLineReplacement', img: sewerLineReplacement},
    {level: 'plumbing', url: '/drain-clearing', title: 'Drain Clearing', 
    id: 'drainClearing', img: drainClearing},
]

const Home = ({thisLocation}:any) => {
    // const params = useParams()
    const thisSeason = getThisSeason()
    const urlParams = new URLSearchParams(window.location.search)

    const [isShowing, setIsShowing] = useState<boolean>(false)
    setTimeout(() => {
        const el1:any = document.querySelector('[href*="https://elfsight.com/google-reviews-widget"]');
        // https://elfsight.com/google-reviews-widget/?utm_source=websites&utm_medium=clients&utm_content=google-reviews&utm_term=aarichardshvac.com&utm_campaign=free-widget
        if(el1) {
            el1.parentNode.removeChild(el1);
            setIsShowing(true)
        } else {
            setIsShowing(false)
        }
    }, 4000)

    return (
        <>
        <Helmet>
            <title>A.A. Richards HVAC Heating, Cooling & Plumbing</title>
            <meta name="keywords" 
                content="Free HVAC Quotes, Heating Repair, Cooling Repair, AC Company"></meta>
            <meta name="description" content="Free HVAC Quotes. Quick Service: New Jersey Heating Repair, 
            AC Repair. Complete Heating & Cooling Services in Middlesex, Monmouth &amp; Ocean County New Jersey." />
            <link rel="canonical" href={'https://aarichardshvac.com' + thisLocation.slug}/>
        </Helmet>
        <Header thisLocation={thisLocation} />
        <main id='home'>
            <Showcase image={{url: thisSeason.showcase, 
            srcSet: thisSeason.srcSet, alt: '', width: '960', height: '400'}} textBox={
                <>
                    <h2>Providing Comfort Since 1974</h2>
                    <p id='servingP'>Serving Middlesex, Monmouth &amp; Ocean 
                        County</p>
                    <h3 id='hPhoneNum'><a href={thisLocation.phone.href}>{thisLocation.phone.display}</a>
                    <img id='coloredEmergencyService' src={coloredEmergencyService}  alt="24/7 Emergency Service"
                     width={150} height={110}/></h3>
                    {/* <Link to="/contactus?reason=Schedule a free estimate" 
                    className='linkBtn scheduleservice'>Book A Free Estimate</Link> */}
                    <a id='showcaseBtn' href={thisLocation.phone.href} className='linkBtn'>Call For A Free Estimate</a>
                </>
            }/>

            <section id='welcome'>
                <h3><strong>{thisSeason.season} is here!</strong> Get your system 
                    check-up today.</h3>
                <h4>Call our {thisLocation.name} office <a href={thisLocation.phone.href}>{thisLocation.phone.display}</a></h4>
                {/* <Link to="/contactus?reason=Schedule a system check-up" 
                    className='linkBtn'>Book A System Check-Up</Link> */}
                    
                    <a href={thisLocation.phone.href} className='linkBtn'>Call For A System Check-Up</a>
              
                    {/* <h2>24/7 AC Service &amp; Repair In Serving Monmouth &amp; Ocean County, NJ</h2> */}
                    {/* <h3>We Are Air Conditioning Repair Professionals, Serving 
                        All of Central New Jersey and the Jersey Shore</h3> */}
    
                <p>We are proud to Serve Families and Businesses in Central Jersey, 
                    so <strong>we Offer Great Discounts For:</strong> Veterans, 
                    Seniors, Firefighters, EMT Members, and Law Enforcement Personnel.</p>
            </section>
            
            {/* <section id='specialdeals' className='infoSection'>
                <LazyLoadImage src={todaysSpecial}
                    alt='Stay cool, and get $500 off with a special summer deal on a new AC unit.' 
                    width='100%' height='auto' />
            </section> */}

            {/* <section id='discounts' className='infoSection'>
                <h2>We Offer Great Discounts For:</h2>
                <div className='imgGallery'>
                    <LazyLoadImage src={veterans} alt='Veterans' width={250} height={166}/>
                    <LazyLoadImage src={seniors} alt='Seniors' width={250} height={166}/>
                    <LazyLoadImage src={firefighters} alt='Firefighters' width={250} height={166}/>
                    <LazyLoadImage src={eMTMembers} alt='EMT Members' width={250} height={166}/>
                    <LazyLoadImage src={lawEnforcementPersonnel} alt='Law Enforcement Personnel' width={250} height={166}/>
                </div>
            </section> */}

            <section id='services' className='infoSection'>
                <div id='serviceguarantees'>
                    <h2>Service Guarantees</h2>
                    <div className='goldBox'>
                        {/* <div className='goldBar'>
                            <LazyLoadImage src={guarantee_service_Financing} alt='One-hour response time'
                             width={200} height={200} />
                        </div> */}

                        <div className='goldBar'>
                            <LazyLoadImage src={guarantee_service_Free_Estimates} alt='Free estimates' width={200} height={200} />
                        </div>

                        <div className='goldBar'>
                            <LazyLoadImage src={guarantee_service_Response_Time} 
                            alt='Financing available for qualified candidates' width={200} height={200} />
                        </div>

                        <div className='goldBar'>
                            <LazyLoadImage src={guarantee_service_Warranty} alt='Service contracts' width={200} height={200} />
                        </div>
                    </div>
                </div>
                <div id='service_gallery'>
                    <h2>Find The Right Service Here</h2>
                    <p>Click any image below to learn more about that service.</p>
                    <div className='imgGallery'>
                        {services.filter((service) => 
                            service.level === (urlParams.get('service_type') ?? 'top')).map((service) => (
                            <Link key={service.id} 
                                to={thisLocation.slug + service.url}
                            className='imgCard'><h3>{service.title}</h3>
                            {service.img ? <LazyLoadImage src={service.img} alt='' width={300} height={169} />:''}</Link>
                        ))}
                        <Link to={thisLocation.slug + "/#service_gallery"}
                         className='imgCard'><h3>All Services</h3></Link>
                       
                    </div>
                </div>

                {/* <div id='heating'>
                    <h3>Heating</h3>
                    <div className='displayBox'>
                        <div className='blockBox right'>
                            <input type="radio" id="heatingRepair" defaultChecked name="heating" value="Repair" />
                            <label htmlFor='heatingRepair' className='box'><GiAutoRepair size={100} />Repair</label>

                            <input type="radio" id="heatingCleaning" name="heating" value="Cleaning" />
                            <label htmlFor='heatingCleaning' className='box'>Cleaning</label>
                    
                            <input type="radio" id="heatingTuneUp" name="heating" value="Tune Up" />
                            <label htmlFor='heatingTuneUp' className='box'>Tune Up</label>
                            
                            <input type="radio" id="heatingInstall" name="heating" value="Install" />
                            <label htmlFor='heatingInstall' className='box'>Install</label>
                            
                            <input type="radio" id="heatingHeatPumps" name="heating" value="Heat Pumps" />
                            <label htmlFor='heatingHeatPumps' className='box'>Heat Pumps</label>
                            
                            <input type="radio" id="oilFurnaces" name="heating" value="Oil Furnaces" />
                            <label htmlFor='oilFurnaces' className='box'>Oil Furnaces</label>
                        </div>
                        <LazyLoadImage src={heating} alt="" width={600} height={338}/>
                    </div>
                </div>

                <div id='cooling'>
                    <h3>Cooling</h3>
                    <div className='displayBox'>
                    <LazyLoadImage src={cooling} alt="" width={600} height={338}/>
                        <div className='blockBox left'>
                            <input type="radio" id="coolingRepair" defaultChecked name="cooling" value="Repair" />
                            <label htmlFor='coolingRepair' className='box'><GiAutoRepair size={100} />Repair</label>

                            <input type="radio" id="coolingCleaning" name="cooling" value="Cleaning" />
                            <label htmlFor='coolingCleaning' className='box'>Cleaning</label>
                    
                            <input type="radio" id="coolingTuneUp" name="cooling" value="Tune Up" />
                            <label htmlFor='coolingTuneUp' className='box'>Tune Up</label>
                            
                            <input type="radio" id="coolingInstall" name="cooling" value="Install" />
                            <label htmlFor='coolingInstall' className='box'>Install</label>
                            
                            <input type="radio" id="heatPumps" name="cooling" value="Heat Pumps" />
                            <label htmlFor='heatPumps' className='box'>Heat Pumps</label>
                            
                            <input type="radio" id="ductless" name="cooling" value="Ductless" />
                            <label htmlFor='ductless' className='box'>Ductless</label>
                        </div>
                    </div>
                </div>

                <div id='airquality'>
                    <h3>Air Quality</h3>
                    <div className='displayBox'>
                        <div className='blockBox right'>
                            <input type="radio" id="airqualityRepair" defaultChecked name="airquality" value="Repair" />
                            <label htmlFor='airqualityRepair' className='box'><GiAutoRepair size={100} />Repair</label>

                            <input type="radio" id="airqualityCleaning" name="airquality" value="Cleaning" />
                            <label htmlFor='airqualityCleaning' className='box'>Cleaning</label>

                            <input type="radio" id="humidifiers" name="airquality" value="Humidifiers" />
                            <label htmlFor='humidifiers' className='box'>Humidifiers</label>

                            <input type="radio" id="airqualityInstall" name="airquality" value="Install" />
                            <label htmlFor='airqualityInstall' className='box'>Install</label>

                            <input type="radio" id="uVFilters" name="airquality" value="UV Filters" />
                            <label htmlFor='uVFilters' className='box'>UV Filters</label>

                            <input type="radio" id="freshAirSystems" name="airquality" value="Fresh Air Systems" />
                            <label htmlFor='freshAirSystems' className='box'>Fresh Air Systems</label>
                        </div>
                        <LazyLoadImage src={airquality} alt="" width={600} height={338}/>
                    </div>
                </div>

                <div id='plumbing'>
                    <h3>Plumbing</h3>
                    <div className='displayBox'>
                    <LazyLoadImage src={plumbing} alt="" width={600} height={338}/>
                        <div className='blockBox left'>
                            <input type="radio" id="plumbingRepair" defaultChecked name="plumbing" value="Repair" />
                            <label htmlFor='plumbingRepair' className='box'><GiAutoRepair size={100} />Repair</label>

                            <input type="radio" id="drinCleaning" name="plumbing" value="Drin Cleaning" />
                            <label htmlFor='drinCleaning' className='box'>Drin Cleaning</label>

                            <input type="radio" id="waterHeaters" name="plumbing" value="Water Heaters" />
                            <label htmlFor='waterHeaters' className='box'>Water Heaters</label>

                            <input type="radio" id="plumbingInstall" name="plumbing" value="Install" />
                            <label htmlFor='plumbingInstall' className='box'>Install</label>

                            <input type="radio" id="radiantHeat" name="plumbing" value="Radiant Heat" />
                            <label htmlFor='radiantHeat' className='box'>Radiant Heat</label>

                            <input type="radio" id="plumbingTuneUp" name="plumbing" value="Tune Up" />
                            <label htmlFor='plumbingTuneUp' className='box'>Tune Up</label>
                        </div>
                    </div>
                </div>*/}
            </section>


            <section  id='brands' className='infoSection'>
                <h2>We have the best brands and prices for heating, cooling & plumbing</h2>
                <p>
                    We carry the best quality brands at the best prices to make your home 
                    comfortable and save you money. Our technical experts here in {thisLocation.name} have 
                    hand-selected the brands we work with and the products that we carry based on their 
                    quality, pricing, customer service, and warranties. We are dedicated 
                    to only carrying products that deliver on that promise for you.
                </p>
                <div>
                    <h3>Goodman Air Conditioner & Furnace Systems</h3>
                    <LazyLoadImage src={goodman} alt='Goodman Air Conditioner & Furnace Systems' 
                        width={400} height={400} />
                </div>

                <div>
                    <h3>Navien Tankless Water Heater</h3>
                    <LazyLoadImage src={navien} alt='Navien Tankless Water Heater' 
                        width={400} height={400} />
                </div>

                <div>
                    <h3>Carrier Air Conditioner & Furnace Systems</h3>
                    <LazyLoadImage src={carrier} alt='Carrier Air Conditioner & Furnace Systems' 
                        width={400} height={400} />
                </div>

                <div>
                    <h3>Trane Air Conditioner & Furnace Systems</h3>
                    <LazyLoadImage src={trane} alt='Trane Air Conditioner & Furnace Systems' 
                        width={400} height={400} />
                </div>
            </section>

            <section id='aboutus' className='infoSection'>
                <h2>About Us</h2>
                {/* <LazyLoadImage className='img' src={theTeam} alt='' width={830} height={550} /> */}
                <div id='theVan'></div>
                <p>AA Richards Heating &amp; Cooling, is a family-owned HVAC 
                    heating, ventilation, air conditioning, service, 
                    and installation company serving Middlesex, Monmouth, and Ocean 
                    Counties since 1974.  Our experienced licensed technicians 
                    are specialists in diagnosing, repairing, or installing 
                    residential heating and air conditioning systems. Recently, 
                    we hired additional operations coordinators to ensure the finest 
                    scheduling, customer service, and customer satisfaction.</p>
            </section>

            <section id='reviews' className='infoSection'>
                <h2>See what our customers are saying!</h2>
                {window.location.hostname !== "localhost" && isShowing ? (
                <div className="elfsight-app-40bc570c-1c74-488a-b305-bdb0c9993cf3"></div>
                ) : (
                    <Reviews />
                )}
            </section>
        </main>
        </>
    )
}

export default Home